import {
  Flex,
  Anchor,
  Space,
  Button,
  Text,
  Divider,
  Drawer,
} from "@mantine/core";
import { ReactComponent as JrmIcon } from "../../assets/icon.svg";
import { IconBookmarkFilled, IconMenuDeep } from "@tabler/icons-react";
import { AppRoutes, navbarLinks } from "../../models";
import styles from "./navbar.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";

export function Navbar() {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hover, setHover] = useState<string>();
  const [highlighted, setHighlighted] = useState<string>("");
  const navigate = useNavigate();

  return (
    <Flex w={"100%"} justify={"center"}>
      <Flex
        py={20}
        // className={styles.pl100}
        style={{ fontFamily: "Inter" }}
        justify={"space-between"}
        w={"70%"}
        // onMouseLeave={() => setHover("")}
      >
        <Flex>
          <JrmIcon
            onClick={() => navigate(AppRoutes.HOME)}
            style={{ cursor: "pointer" }}
          />
          <Space w={30} />
        </Flex>
        {/* <Flex className={styles.navResponsive} align={"center"}>
          {navbarLinks.map((item) => {
            return (
              <Flex key={item.title} className={styles.navResponsive}>
                <Anchor
                  href={item.href}
                  target="_blank"
                  underline="hover"
                  c={"black"}
                  onMouseOver={() => setHover(item.title)}
                >
                  {item.title}
                </Anchor>
                <Space w={30} />
                {item.subMenuItems && (
                  <Flex
                    direction={"column"}
                    bg={"#f9f9f9"}
                    style={{ borderRadius: "6px" }}
                    pos={"absolute"}
                    top={60}
                    display={hover === item.title ? "flex" : "none"}
                  >
                    {item.subMenuItems?.map((subMenuItem, j) => {
                      return (
                        <>
                          <Anchor
                            px={18}
                            py={8}
                            href={subMenuItem.href}
                            target="_blank"
                            underline="never"
                            c={"black"}
                            key={subMenuItem.title}
                            bg={
                              highlighted === subMenuItem.title ? "#efefef" : ""
                            }
                            onMouseOver={() =>
                              setHighlighted(subMenuItem.title)
                            }
                            onMouseLeave={() => setHighlighted("")}
                            style={{ borderRadius: "6px" }}
                          >
                            {subMenuItem.title}
                          </Anchor>
                          {j !== item.subMenuItems.length - 1 && <Divider />}
                        </>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Flex> */}
        <Flex align={"center"} className={styles.navResponsive}>
          <IconBookmarkFilled />
          <Space w={30} />
          <Button
            color="black"
            w={100}
            h={45}
            radius={"sm"}
            variant="default"
            style={{ border: "2px solid black" }}
            onClick={() => navigate(`${AppRoutes.LOGIN}`)}
          >
            <Text size="sm">LOGIN</Text>
          </Button>
          <Space w={30} />
          <Button
            color="black"
            w={100}
            h={45}
            radius={"sm"}
            onClick={() => navigate(`${AppRoutes.SIGNUP}`)}
          >
            <Text size="sm">SIGN UP</Text>
          </Button>
        </Flex>
        <Flex
          className={styles.navResponsiveIcon}
          align={"center"}
          justify={"center"}
          onClick={() => setExpanded(!expanded)}
        >
          <IconMenuDeep />
        </Flex>
      </Flex>
      {expanded && (
        // <Flex
        //   bg={"#efefef"}
        //   h={520}
        //   justify={"space-between"}
        //   align={"space-between"}
        //   className={styles.navExpandedMenu}
        // >
        //   <Flex direction={"column"} w={"100%"} p={40}>
        //     {navbarLinks.map((item) => {
        //       return (
        //         <>
        //           <Anchor
        //             href={item.href}
        //             target="_blank"
        //             underline="hover"
        //             c={"black"}
        //             className={styles.navExpandedMenuItem}
        //           >
        //             <Text
        //               style={{
        //                 fontSize: "16px",
        //                 lineHeight: 2,
        //               }}
        //             >
        //               {item.title}
        //             </Text>
        //           </Anchor>
        //           {item.subMenuItems?.map((subMenuItem) => {
        //             return (
        //               <Anchor
        //                 href={subMenuItem.href}
        //                 target="_blank"
        //                 underline="hover"
        //                 c={"black"}
        //                 className={styles.navExpandedMenuItem}
        //               >
        //                 <Text
        //                   style={{
        //                     fontSize: "16px",
        //                     lineHeight: 2,
        //                   }}
        //                 >
        //                   {subMenuItem.title}
        //                 </Text>
        //               </Anchor>
        //             );
        //           })}
        //         </>
        //       );
        //     })}
        //   </Flex>
        //   <Flex w={"100%"}></Flex>
        // </Flex>

        <Drawer
          opened={expanded}
          onClose={() => setExpanded(false)}
          position={"right"}
          // withCloseButton={false}
          overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
          transitionProps={{
            transition: "rotate-left",
            duration: 150,
            timingFunction: "linear",
          }}
          styles={{
            content: {
              height: "250",
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              // marginTop: 55,
            },
            overlay: {
              height: "250",
              // marginTop: 55,
            },
          }}
        ></Drawer>
      )}
    </Flex>
  );
}
