import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { JobService } from "../../services/jobsService";
import { AppRoutes, Fonts, footerSocialLinks } from "../../models";
import { Anchor, Button, Flex, Modal, rem, Space, Text, Tooltip } from "@mantine/core";
import { DateUtils, StringUtils } from "../../utils";
import { IconClock, IconCoin, IconCopy, IconShare } from "@tabler/icons-react";
import { Country, Job } from "../../types";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import { RootState, setRedirectionJob } from "../../store";
import store from "../../store/store";
import { titleService } from "../../services";
import { notifications } from '@mantine/notifications';
import { useDisclosure } from "@mantine/hooks";

export function JobDescription() {

    const countryCode: string = useParams().cc as string;
    const title: string = useParams().title as string;
    const senderReference: string = useParams().sr as string;
    const country: Country = useSelector((state: RootState) => state.userMetaData.clientCountry) as Country;

    const [job, setJob] = useState<Job | null>(null);
    const jobService: JobService = new JobService();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const [jobTypeApplicationModalOpened, jobTypeApplicationModalActions] = useDisclosure(false);

    useEffect(() => {
        findJob();
    }, [senderReference])

    useEffect(() => {
        titleService.setTitle(`Hiring ${job?.title} in ${job?.city},${job?.region},${job?.country_code} during ${DateUtils.getCurrentYear()}`);
    }, [job]);

    async function findJob() {
        const fetchedJobs: Job[] = await jobService.findJobBySenderReference(senderReference);
        setJob(fetchedJobs.at(0) as Job);
    }

    function getClipboardCopyText() {
        return `#hiring ${job?.title} in ${job?.city},${job?.region},${job?.country_code} during ${DateUtils.getCurrentYear()} To Apply vist: ${window.location.toString()} #jobSeekers #${job?.category} #${job?.employment_type}`;
    }

    return (
        <>
            {job &&
                <Flex
                    direction={"column"}
                    w={"100%"}
                    align={"center"}
                    justify={"center"}
                    my={50}
                    style={{ fontFamily: Fonts.INTER }}
                >
                    <Flex
                        bg={"#FAFAFA"}
                        w={"80vw"}
                        p={32}
                        style={{ borderRadius: 8 }}
                        direction={"column"}
                    >
                        <Flex
                            justify={"space-between"}
                            align={"center"}
                            mb={32}
                        >
                            <Flex align={"center"} style={{ flexWrap: "wrap" }}>
                                {job.logo_url && <img width={100} src={job.logo_url} />}
                                <Anchor underline="never" ml={16}>
                                    <Text
                                        fw={"bold"}
                                        style={{ fontSize: 32 }}
                                        c={"black"}
                                    >
                                        {StringUtils.toCaptilize(job.title)}
                                    </Text>
                                </Anchor>
                            </Flex>

                        </Flex>

                        <Flex justify={"space-between"} align={"center"} style={{ flexWrap: "wrap" }}>
                            <Flex direction={"column"}>
                                <Flex>
                                    <IconClock style={{ height: rem(20), width: rem(20) }} />
                                    <Space w={12} />
                                    <Text
                                        fw={500}
                                        c={"#4d4d4d"}
                                    >
                                        {StringUtils.toCaptilize(job.employment_type)}
                                    </Text>
                                </Flex>

                                <Flex>
                                    <IconCoin style={{ height: rem(20), width: rem(20) }} />
                                    <Space w={12} />
                                    <Text
                                        fw={500}
                                        c={"#4d4d4d"}
                                    >
                                        Salary: {country?.currencySymbol}{job.salary_minimum} - {country?.currencySymbol}{job.salary_maximum} {job.salary_currency} {job.salary_period}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Text
                                        fw={500}
                                        c={"#4d4d4d"}
                                    >
                                        Posted: {DateUtils.getTimeFromNow(job.post_date)}
                                    </Text>
                                </Flex>
                                <Flex>
                                    <Text
                                        fw={500}
                                        c={"#4d4d4d"}
                                    >
                                        Posted by: {StringUtils.toCaptilize(job.advertiser_name)}
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex
                                my={12}
                                align={"center"}
                                onClick={() => {
                                    if (job.job_type !== "APPLICATION") {
                                        store.dispatch(setRedirectionJob(job));
                                        navigate(AppRoutes.COMPLETE_APPLICATION);
                                    }
                                    else {
                                        jobTypeApplicationModalActions.open();
                                    }
                                }}
                            >
                                <Anchor c={"#ffffff"} size="sm" fw={600}>
                                    <Button color="black" w={120} h={45} radius={"md"}>
                                        Apply
                                    </Button>
                                </Anchor>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        my={30}
                        justify={"space-between"}
                        w={"80vw"}
                    >
                        <Flex
                            bg={"#fafafa"}
                            p={24}
                            style={{ borderRadius: 8 }}
                            direction={"column"}
                            w={"100%"}
                        >
                            <Flex
                                justify={"space-between"}
                                w={"100%"}
                            >
                                <Text
                                    size="xl"
                                    fw={500}
                                >
                                    Job Details
                                </Text>
                                <Flex
                                    justify={"space-between"}
                                // w={150}
                                >
                                    {/* {footerSocialLinks.map((item) =>
                                        <item.icon />
                                    )} */}
                                    <Tooltip label="Share Job">
                                        <IconShare style={{ cursor: "pointer" }} onClick={() => {
                                            navigator.clipboard.writeText(getClipboardCopyText());
                                            notifications.show({
                                                message: "Job Copied to clipboard",
                                                color: "Green",
                                                title: "Success",
                                                position: "bottom-left",
                                            })
                                        }} />
                                    </Tooltip>
                                </Flex>
                            </Flex>

                            <Text
                                c={"#4d4d4d"}
                                my={6}
                                ta={"justify"}
                            >
                                {parse(job.description)}
                            </Text>

                            <Flex
                                my={12}
                                align={"center"}
                                justify={"center"}
                                onClick={() => {
                                    if (job.job_type !== "APPLICATION") {
                                        store.dispatch(setRedirectionJob(job));
                                        navigate(AppRoutes.COMPLETE_APPLICATION);
                                    }
                                    else {
                                        jobTypeApplicationModalActions.open();
                                    }
                                }}
                            >
                                <Anchor c={"#ffffff"} size="sm" fw={600}>
                                    <Button color="black" w={120} h={45} radius={"md"}>
                                        Apply Now
                                    </Button>
                                </Anchor>
                            </Flex>
                        </Flex>
                    </Flex>

                </Flex>
            }

            <Modal opened={jobTypeApplicationModalOpened} onClose={jobTypeApplicationModalActions.close} title={`Apply for ${job?.title}`} size={"xl"}>
                <Flex h={700}>
                    <iframe src={job?.application_url} width={"100%"} height={"100%"} style={{border: "0px solid #ffffff"}}></iframe>
                </Flex>
            </Modal>
        </>

    );
}