export type Country = {
    countryId: number;
    continentId: number;
    code: string;
    countryName: string;
    capital: string;
    capitalRegion: string;
    fullName: string;
    iso3: string;
    currency: string;
    status: number;
    currencyCode: string;
    currencyName: string;
    currencySymbol: string;
    flag: string;
    salaryMin: number;
    salaryMax: number;
    distance: string;
}

export const defaultCountrySelection: Country = {
    "countryId": 228,
    "continentId": 4,
    "code": "US",
    "countryName": "United States",
    "capital": "Washington",
    "capitalRegion": "District of Columbia",
    "fullName": "United States of America",
    "iso3": "USA",
    "currency": "USD",
    "status": 1,
    "currencyCode": "USD",
    "currencyName": "Dollar",
    "currencySymbol": "$",
    "flag": "US.png",
    "salaryMin": 0,
    "salaryMax": 200000,
    "distance": "mi"
}