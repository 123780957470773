import axios from "axios";
import { environment } from "../environment";

export class IpAddressResolverService {

    async resolveClientIpAddress(): Promise<string> {
        let response = await axios.get(environment.ipAddressResolverUrl);
        console.log("ClientIpAddress: ", response.data);
        return response.data.ip;
    }

}