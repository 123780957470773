import { Flex, Loader, rem, Text } from "@mantine/core";

export function AppLoader(props?: { message?: string }) {
  const message: string =
    props?.message || "Hang up tight we are loading this for you";
  return (
    <Flex
      w={"100vw"}
      h={"100vh"}
      justify={"center"}
      align={"center"}
      direction={"column"}
    >
      <Loader style={{ width: rem(100), height: rem(100) }} />
      <Text size="xl" fw={500} m={10} c={"#4d4d4d"}>
        {message}
      </Text>
    </Flex>
  );
}
