import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../types";

export type RedirectPageSlice = {
    job: Job | null;
};

const redirectPageSliceState: RedirectPageSlice = {
    job: null,
};

export const redirectPageSlice = createSlice({
    name: "redirectPageSlice",

    initialState: redirectPageSliceState,
    reducers: {
        setRedirectionJob(state: RedirectPageSlice, action: PayloadAction<Job>) {
            state.job = action.payload;
            return state;
        },
        resetRedirectionJob(state: RedirectPageSlice) {
            state.job = null;
            return state;
        }
    }
});

export const {
    setRedirectionJob,
    resetRedirectionJob
} = redirectPageSlice.actions;

export default redirectPageSlice.reducer;