import { Flex, Space, Text } from "@mantine/core";
import { useState } from "react";
import { AppRoutes, Fonts } from "../../models";
import { useNavigate, useSearchParams } from "react-router-dom";

export function MostPopularIndustryCard(props: any) {
  const [highlighted, setHighlighted] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [label] = useState(props.label);
  const [count] = useState(props.count);

  async function searchJobsByCategory() {
    navigate(AppRoutes.JOB_SEARCH);
    setSearchParams({
      categories: label,
    });
  }

  return (
    <Flex
      bg={highlighted ? "#ffffff" : "#F9F9F9"}
      align={"center"}
      justify={"center"}
      direction={"column"}
      onMouseOver={() => setHighlighted(true)}
      onMouseLeave={() => setHighlighted(false)}
      onClick={() => searchJobsByCategory()}
      h={200}
      w={300}
      m={12}
      style={{
        borderRadius: 8,
        boxShadow: highlighted
          ? "rgba(0, 0, 0, 0.15) 0px 0px 100px 12px"
          : "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px",
        cursor: "pointer",
      }}
    >
      <Text fw={500} size={"xl"} ff={Fonts.NEWSREADER}>
        {label}
      </Text>
      <Space h={16} />
      <Text size="sm" c={"dimmed"}>
        {count}+ Jobs
      </Text>
    </Flex>
  );
}
