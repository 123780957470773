import { Flex, rem, Space, Text } from "@mantine/core";
import { Fonts, RawText, howDoesItWorksCardsData } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";

export function HowItWorks() {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);
  
  return (
    <Flex direction={"column"} w={"100%"} justify={"center"} align={"center"}>
      <Text
        style={{
          fontFamily: Fonts.NEWSREADER,
          fontSize: isDesktop ? rem(48) : isTab ? rem(32) : rem(24),
        }}
      >
        {RawText.HOW_IT_WORKS}
      </Text>

      <Flex justify={"center"} align={"center"} w={"100%"} wrap={"wrap"}>
        {howDoesItWorksCardsData.map((data: any, index: number) => (
          <Flex
            direction={"column"}
            p={32}
            bg={"#F9F9F9"}
            align={"center"}
            justify={"center"}
            h={400}
            m={6}
            style={{
              borderRadius: 8,
              boxShadow: "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px",
              cursor: "pointer",
            }}
          >
            <Flex mt={""}>{data.icon && <data.icon />}</Flex>
            <Space h={30} />
            <Text size="xl" fw={"bold"}>
              {data.title ? data.title : ""}
            </Text>
            <Text c={"dimmed"} size="xs" ta={"center"}>
              {data.description ? data.description : ""}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
