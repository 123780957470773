import { createSlice, PayloadAction, PayloadActionCreator } from "@reduxjs/toolkit";
import { MAX_SALARY, MIN_SALARY } from "../models";

export type SearchFilterSlice = {
    keyword: string;
    locations: string[];
    employmentTypes: string[];
    distance: string;
    categories: string[];
    salaryMin: number;
    salaryMax: number;
};

const searchFilterSliceState: SearchFilterSlice = {
    keyword: "",
    locations: [],
    employmentTypes: [],
    distance: "",
    categories: [],
    salaryMin: MIN_SALARY,
    salaryMax: MAX_SALARY
};

export const searchFilterSlice = createSlice({
    name: "searchFilterSlice",

    initialState: searchFilterSliceState,
    reducers: {
        setKeyword(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.keyword = action.payload;
            return state;
        },
        resetKeyword(state: SearchFilterSlice) {
            state.keyword = "";
            return state;
        },
        setLocations(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.locations = action.payload;
            return state;
        },
        resetLocation(state: SearchFilterSlice) {
            state.locations = [];
            return state;
        },
        setEmploymentType(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.employmentTypes = action.payload;
            return state;
        },
        resetEmploymentType(state: SearchFilterSlice) {
            state.employmentTypes = [];
            return state;
        },
        setDistance(state: SearchFilterSlice, action: PayloadAction<string>) {
            state.distance = action.payload;
            return state;
        },
        resetDistance(state: SearchFilterSlice) {
            state.distance = "";
            return state;
        },
        setCategories(state: SearchFilterSlice, action: PayloadAction<string[]>) {
            state.categories = action.payload;
            return state;
        },
        resetCategories(state: SearchFilterSlice) {
            state.categories = [];
            return state;
        },
        setSalaryMin(state: SearchFilterSlice, action: PayloadAction<number>) {
            state.salaryMin = action.payload;
            return state;
        },
        resetSalaryMin(state: SearchFilterSlice) {
            state.salaryMin = MIN_SALARY;
            return state;
        },
        setSalaryMax(state: SearchFilterSlice, action: PayloadAction<number>) {
            state.salaryMax = action.payload;
            return state;
        },
        resetSalaryMax(state: SearchFilterSlice) {
            state.salaryMax = MAX_SALARY;
            return state;
        },
        resetSearchFilter(state: SearchFilterSlice) {
            state.keyword = "";
            state.locations = [];
            state.employmentTypes = [];
            state.distance = "";
            state.categories = [];
            state.salaryMin = MIN_SALARY;
            state.salaryMax = MAX_SALARY;
            return state;
        }
    }
});

export const {
    setKeyword,
    resetKeyword,
    setLocations,
    resetLocation,
    setEmploymentType,
    resetEmploymentType,
    setDistance,
    resetDistance,
    setCategories,
    resetCategories,
    setSalaryMin,
    resetSalaryMin,
    setSalaryMax,
    resetSalaryMax,
    resetSearchFilter
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;