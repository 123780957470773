import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Country, Location } from "../types";

export type UserMetaData = {
    clientIp: string | null;
    clientCountry: Country | null;
    clientAvailableLocations: Location[];
    availableJobCategories: string[];
    availableEmploymentTypes: string[]; 
};

const userMetaDataState: UserMetaData = {
    clientIp: null,
    clientCountry: null,
    clientAvailableLocations: [],
    availableJobCategories: [],
    availableEmploymentTypes: [],
};

export const userMetaDataSlice = createSlice({
    name: "userMetaData",

    initialState: userMetaDataState,
    reducers: {
        setClientIp(state: UserMetaData, action: PayloadAction<string>) {
            state.clientIp = action.payload;
            return state;
        },
        resetClientIp(state: UserMetaData) {
            state.clientIp = null;
            return state;
        },
        setClientCountry(state: UserMetaData, action: PayloadAction<Country | null>) {
            state.clientCountry = action.payload;
            return state;
        },
        resetClientCountry(state: UserMetaData, action: PayloadAction<string>) {
            state.clientCountry = null;
            return state;
        },
        resetUserMetaDataSlice(state: UserMetaData) {
            state.clientIp = null;
            state.clientCountry = null;
            return state;
        },
        setClientAvailableLocations(state: UserMetaData, action: PayloadAction<Location[]>) {
            state.clientAvailableLocations = action.payload;
            return state;
        },
        resetClientAvailableLocations(state: UserMetaData) {
            state.clientAvailableLocations = [];
            return state;
        },
        setAvailableJobCategories(state: UserMetaData, action: PayloadAction<string[]>) {
            state.availableJobCategories = action.payload;
            return state;
        },
        resetAvailableJobCategories(state: UserMetaData) {
            state.availableJobCategories = [];
            return state;
        },
        setAvailableEmploymentTypes(state: UserMetaData, action: PayloadAction<string[]>) {
            state.availableEmploymentTypes = action.payload;
            return state;
        },
        resetAvailableEmploymentTypes(state: UserMetaData) {
            state.availableJobCategories = [];
            return state;
        },
    }
})

export const {
    setClientIp,
    resetClientIp,
    setClientCountry,
    resetClientCountry,
    resetUserMetaDataSlice,
    setClientAvailableLocations,
    resetClientAvailableLocations,
    setAvailableJobCategories,
    resetAvailableJobCategories,
    setAvailableEmploymentTypes,
    resetAvailableEmploymentTypes
} = userMetaDataSlice.actions;

export default userMetaDataSlice.reducer;