import {
  Anchor,
  Button,
  Collapse,
  Flex,
  rem,
  Select,
  Skeleton,
  Space,
  Text,
} from "@mantine/core";
import {
  SearchFilterComponent,
  MostPopularIndustryCard,
  HowItWorks,
} from "../../components";
import { ReactComponent as IconArrowDown } from "../../assets/iconArrowDown.svg";
import { AppRoutes, Fonts, RawText } from "../../models";
import styles from "./homepage.module.css";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useEffect, useState } from "react";
import {
  Country,
  EmployerCountByCategory,
  JobCountByCategory,
} from "../../types";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { JobService } from "../../services/jobsService";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { titleService } from "../../services";
import { ScreenSize } from "../../utils";
import CountUp from "react-countup";
import { LargeJobCardComponent } from "../../components/largeJobCard/largeJobCard.component";

export function Homepage() {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const [demandingCategories, setDemandingCategories] = useState<
    JobCountByCategory[]
  >([]);
  const [employerCountByCategory, setEmployerCountByCategory] = useState<
    EmployerCountByCategory[]
  >([]);
  const [employers, setEmployers] = useState<string[]>([]);
  const [allJobsCount, setAllJobsCount] = useState<number>();
  const [allEmployersCount, setAllEmployersCount] = useState<number>();
  const [allIndustryCount, setAllIndustryCount] = useState<number>();
  const [expandedIndusties, expandIndustiesAction] = useDisclosure(false);

  const clientCountry: Country | null = useSelector(
    (state: RootState) => state.userMetaData.clientCountry
  );
  const jobService: JobService = new JobService();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    titleService.setTitle(
      "Jobs Near Me | Jobs Hiring Near Me | Hiring Near Me | Jobsrmine.com"
    );
    initHomepage();
  }, []);

  async function initHomepage() {
    if (clientCountry) {
      setLoading(true);
      const jobsCount = await jobService.findAllJobsCount();
      setAllJobsCount(jobsCount);

      const employersCount = await jobService.findAllEmployersCount();
      setAllEmployersCount(employersCount);

      const industryCount = await jobService.findAllIndustryCount();
      setAllIndustryCount(industryCount);

      const employerCountByCategory: EmployerCountByCategory[] =
        await jobService.getEmployerCountByCategory(clientCountry.countryName);
      setEmployerCountByCategory(employerCountByCategory);

      const employersByCountry: string[] =
        await jobService.findEmployersByCountry(clientCountry.countryName);
      setEmployers(employersByCountry);

      const demandingCategories: JobCountByCategory[] =
        await jobService.getJobCountByCategory(clientCountry.countryName);
      setDemandingCategories(demandingCategories);

      setLoading(false);
    }
  }

  async function searchByEmployer() {
    navigate(AppRoutes.JOB_SEARCH);
    setSearchParams({
      employers: [searchByEmployerForm.getTransformedValues().employer],
    });
  }

  const searchByEmployerForm = useForm({
    initialValues: {
      employer: "",
    },
    validate: {
      employer: (value) => (value.length ? null : "Select a valid employer"),
    },
  });

  return (
    <>
      <Flex
        py={100}
        justify={"center"}
        align={"center"}
        direction={"column"}
        w={"100%"}
      >
        <Flex w={"70%"} ff={Fonts.NEWSREADER}>
          <Text
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(32) : rem(24),
            }}
            ta={"center"}
          >
            {RawText.HOME_PAGE_DESC}
          </Text>
        </Flex>
        <SearchFilterComponent />

        <Space h={40} />
        <Flex
          onClick={() =>
            window.scroll({ top: 690, left: 0, behavior: "smooth" })
          }
          style={{ cursor: "pointer" }}
        >
          <IconArrowDown className={styles.animateIcon} />
        </Flex>

        <Space h={40} />

        <Flex
          align={"center"}
          justify={"space-around"}
          w={"70%"}
          style={{ fontFamily: "Inter" }}
          wrap={"wrap"}
        >
          {allJobsCount && !loading ? (
            <Flex
              direction={"column"}
              justify={"center"}
              align={"center"}
              m={15}
            >
              <CountUp
                end={allJobsCount}
                duration={2}
                style={{
                  fontSize: "36px",
                  fontFamily: Fonts.INTER,
                  fontWeight: 600,
                }}
              />
              <Text size="xs">Openings</Text>
            </Flex>
          ) : (
            <Skeleton w={200} h={100} m={6} />
          )}
          {allEmployersCount && !loading ? (
            <Flex
              direction={"column"}
              justify={"center"}
              align={"center"}
              m={15}
            >
              <CountUp
                end={allEmployersCount}
                duration={2}
                style={{
                  fontSize: "36px",
                  fontFamily: Fonts.INTER,
                  fontWeight: 600,
                }}
              />
              <Text size="xs">Companies</Text>
            </Flex>
          ) : (
            <Skeleton w={200} h={100} m={6} />
          )}
          {allIndustryCount && !loading ? (
            <Flex
              direction={"column"}
              justify={"center"}
              align={"center"}
              m={15}
            >
              <CountUp
                end={allIndustryCount}
                duration={2}
                style={{
                  fontSize: "36px",
                  fontFamily: Fonts.INTER,
                  fontWeight: 600,
                }}
              />
              <Text size="xs">Industies/Categories</Text>
            </Flex>
          ) : (
            <Skeleton w={200} h={100} m={6} />
          )}
        </Flex>

        <Flex my={64} direction={"column"} align={"center"} w={"70%"}>
          <Text
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(32) : rem(24),
            }}
            ff={Fonts.NEWSREADER}
          >
            {RawText.MOST_POPULAR_INDUSTRIES}
          </Text>
          {!expandedIndusties && (
            <Flex justify={"center"} align={"center"} w={"100%"} wrap={"wrap"}>
              {!loading &&
                demandingCategories
                  .map((item: JobCountByCategory, index: number) => (
                    <MostPopularIndustryCard
                      key={index}
                      label={item.category}
                      count={item.job_count}
                    />
                  ))
                  .splice(0, 6)}
              {!demandingCategories.length &&
                [0, 1, 2, 3, 4, 5].map((i) => (
                  <Skeleton w={300} h={200} m={12}></Skeleton>
                ))}
            </Flex>
          )}
          <Collapse in={expandedIndusties}>
            <Flex justify={"center"} align={"center"} w={"100%"} wrap={"wrap"}>
              {demandingCategories
                .map((item: JobCountByCategory, index: number) => (
                  <MostPopularIndustryCard
                    key={index}
                    label={item.category}
                    count={item.job_count}
                  />
                ))
                .splice(0)}
            </Flex>
          </Collapse>

          <Space h={30} />
          <Text
            td="underline"
            fw={"bold"}
            onClick={expandIndustiesAction.toggle}
            style={{ cursor: "pointer" }}
          >
            {!expandedIndusties ? "Explore All Industries" : "Collapse"}
          </Text>
        </Flex>

        {/* <Flex my={50} w={isDesktop ? "70%" : "80%"} direction={"column"}>
          {demandingCategories.map((item: JobCountByCategory) => (
            <>
              <LargeJobCardComponent
                data={item}
                employerCount={employerCountByCategory.find(
                  (employerCount: EmployerCountByCategory) =>
                    employerCount.category === item.category
                )}
              />
              <Space h={32} />
            </>
          ))}
        </Flex> */}

        {!isMobile && (
          <Flex my={50} w={"100%"}>
            <HowItWorks />
          </Flex>
        )}

        <Flex direction={"column"} w={"100%"}>
          <Flex justify={"center"}>
            <Text
              style={{
                fontSize: isDesktop ? rem(48) : isTab ? rem(32) : rem(24),
              }}
              ff={Fonts.NEWSREADER}
            >
              Search by Employer
            </Text>
          </Flex>
          <Flex
            justify={"center"}
            w={"100%"}
            direction={isMobile ? "column" : "row"}
            p={24}
          >
            <Select
              placeholder="Search by Company"
              w={isDesktop ? "40%" : isTab ? "60%" : "100%"}
              mr={14}
              data={employers}
              searchable
              {...searchByEmployerForm.getInputProps("employer")}
              limit={200}
              my={isMobile ? 8 : 0}
            />
            <Button bg={"black"} onClick={searchByEmployer}>
              Search
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
