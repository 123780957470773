import { ReactComponent as FacebookIcon } from "../../assets/facebook.svg"
import { ReactComponent as LinkedInIcon } from "../../assets/linkedIn.svg"
import { ReactComponent as WhatsAppIcon } from "../../assets/whatsApp.svg"
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg"
import { ReactComponent as HowItWorks1 } from "../../assets/howItWorks1.svg"
import { ReactComponent as HowItWorks2 } from "../../assets/howItWorks2.svg"
import { ReactComponent as HowItWorks3 } from "../../assets/howItWorks3.svg"

export enum RawText {
    HOME_PAGE_DESC = "Put your job hunt on the fast track with jobsRmine, get a better job, feel happy.",
    LOOKING_FOR_CANDIDATE = "Looking for candidates?",
    POST_A_JOB = "Post a job now",
    EXPLORE_ALL_INDUSTRIES = "Explore All Industries",
    MOST_POPULAR_INDUSTRIES = "Most Popular Industries",
    HOW_IT_WORKS = "How Does It Works?",
}

export const AddressLines: string[] = [
    "40 Central Avenue, Sale,",
    "Manchester M33 4JA,",
    "United Kingdom"
];

export const contactLines: string[] = [
    "+44-774 105 4514 (Not Recruiter contact)",
    "support@jobsrmine.com"
];

export const footerQuickLinks = [
    {
        title: "Signup / Login",
        href: "#",
    },
    {
        title: "Easy Search",
        href: "#",
    },
    {
        title: "FAQ",
        href: "#",
    },
    {
        title: "Sitemap",
        href: "#",
    },
    {
        title: "Jobs RSS",
        href: "#",
    },
    {
        title: "Feeds",
        href: "#",
    },
    {
        title: "Visa Sponsorship Jobs",
        href: "#",
    },
    {
        title: "Download FREE CV templates",
        href: "#",
    },
    {
        title: "Telegram group invite links",
        href: "#",
    },
    {
        title: "Free Job Alerts",
        href: "#",
    },
]

export const footerBusinessLinks = [
    {
        title: "About us",
        href: "#",
    },
    {
        title: "Careers at jobsRmine®",
        href: "#",
    },
    {
        title: "Advertisers",
        href: "#",
    },
    {
        title: "Terms & Privacy Policies",
        href: "#",
    },
    {
        title: "Imprint",
        href: "#",
    },
    {
        title: "GDPR notice",
        href: "#",
    },
    {
        title: "Contact us",
        href: "#",
    },
]

export const footerSocialLinks = [
    {
        icon: FacebookIcon,
        title: "Facebook",
        href: "#",
    },
    {
        icon: LinkedInIcon,
        title: "LinkedIn",
        href: "#",
    },
    {
        icon: WhatsAppIcon,
        title: "WhatsApp",
        href: "#",
    },
    {
        icon: TwitterIcon,
        title: "X formely Twitter",
        href: "#",
    }
]

export const howDoesItWorksCardsData = [
    {
        icon: HowItWorks1,
        iconMargin: "2%",
        title: "Step 1: Create An Account",
        description: "It's very easy to open an account and start your journey.",
    },
    {
        icon: HowItWorks2,
        iconMargin: "2%",
        title: "Step 2: Complete Your Profile",
        description: "Complete your profile with all the info to get attention of client.",
    },
    {
        icon: HowItWorks3,
        iconMargin: "2%",
        title: "Step 3: Apply Jobs Or Hire",
        description: "Apply & get your preferable jobs with all the requirements and get it.",
    }
];

export const MAX_SALARY: number = 300000;
export const MIN_SALARY: number = 0;