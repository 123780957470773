import axios, { AxiosInstance } from "axios";
import { environment } from "../environment";

export class SearchApiConnector {
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: environment.manticoreApiUrl
        })
    }
};