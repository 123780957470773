import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../types";

export type SearchResultPageSlice = {
    activeJob: Job | null
};

const searchResultPageState: SearchResultPageSlice = {
    activeJob: null,
}

export const searchResultPageSlice = createSlice({
    name: "searchResultPage",

    initialState: searchResultPageState,
    reducers: {
        setActiveJob(state: SearchResultPageSlice, action: PayloadAction<Job>) {
            state.activeJob = action.payload;
            return state;
        },
        resetActiveJob(state: SearchResultPageSlice) {
            state.activeJob = null;
            return state;
        }
    }
});


export const {
    setActiveJob,
    resetActiveJob
} = searchResultPageSlice.actions;

export default searchResultPageSlice.reducer;