import { Anchor, Flex, Space, Text } from "@mantine/core";
import { ReactComponent as JrmIcon } from '../../assets/icon.svg';
import { AddressLines, Fonts, contactLines, footerBusinessLinks, footerQuickLinks, footerSocialLinks } from "../../models";
import { useMediaQuery } from "@mantine/hooks";

export function Footer() {

    const isDesktop = useMediaQuery('(min-width: 900px)');

    return (
        <Flex
            justify={"space-between"}
            w={"100%"}
            style={{ borderTop: "1px solid #ececec", fontFamily: Fonts.INTER }}
            px={"10%"}
            py={"5%"}
            wrap={"wrap"}
            bg={"#ffffff"}
        >
            <Flex
                direction={"column"}
                m={15}
            >

                <JrmIcon />
                <Space h={30} />
                <Text
                    fw={"bold"}
                    size="lg"
                >
                    Our Registered Address
                </Text>
                <Space h={30} />
                {AddressLines.map((line: string) => <Text mb={8}>{line}</Text>)}
                <Space h={30} />
                {contactLines.map((line: string) => <Text mb={8}>{line}</Text>)}
            </Flex>
            <Flex
                direction={"column"}
                m={15}
            >
                <Text
                    fw={"bold"}
                    size="lg"
                >
                    Quick Links
                </Text>
                <Space h={30} />
                {footerQuickLinks.map((item) =>
                    <Anchor href={item.href} target="_blank" underline="hover" c={"black"} mb={8}>
                        {item.title}
                    </Anchor>
                )}
            </Flex>
            <Flex
                direction={"column"}
                m={15}
            >
                <Text
                    fw={"bold"}
                    size="lg"
                >
                    Business Links
                </Text>
                <Space h={30} />
                {footerBusinessLinks.map((item) =>
                    <Anchor href={item.href} target="_blank" underline="hover" c={"black"} mb={8}>
                        {item.title}
                    </Anchor>
                )}
            </Flex>
            <Flex
                direction={"column"}
                m={15}
            >
                <Text
                    fw={"bold"}
                    size="lg"
                >
                    Social
                </Text>
                <Space h={30} />
                {footerSocialLinks.map((item) =>
                    <Flex>
                        <item.icon />
                        <Space w={10} />
                        <Anchor href={item.href} target="_blank" underline="hover" c={"black"} mb={8}>
                            {item.title}
                        </Anchor>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}