import {
  ActionIcon,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Drawer,
  Flex,
  RangeSlider,
  rem,
  Select,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppRoutes, MAX_SALARY, MIN_SALARY } from "../../models";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Location } from "../../types";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";
import { CustomMultiSelect } from "../customMultiSelect/customMultiSelect.component";
import {
  IconAdjustmentsAlt,
  IconChevronDown,
  IconChevronRight,
  IconSearch,
} from "@tabler/icons-react";
import {
  resetSearchFilter,
  setCategories,
  setDistance,
  setEmploymentType,
  setKeyword,
  setLocations,
  setSalaryMax,
  setSalaryMin,
} from "../../store/searchFilterSlice";
import store from "../../store/store";

export function SearchFilterComponent(props: any) {
  const loading: boolean = props.loading;
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const [expanded, setExpanded] = useState<boolean>(false);

  const [availableSearchLocations, setAvailableSearchLocations] = useState<
    string[]
  >([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  let availableJobCategories: string[] = useSelector(
    (state: RootState) => state.userMetaData.availableJobCategories
  );
  let availableEmploymentTypes: string[] = useSelector(
    (state: RootState) => state.userMetaData.availableEmploymentTypes
  );
  let clientAvailableLocations: Location[] = useSelector(
    (state: RootState) => state.userMetaData.clientAvailableLocations
  );

  let searchKeyword = useSelector(
    (state: RootState) => state.searchFilter.keyword
  );
  let locations = useSelector(
    (state: RootState) => state.searchFilter.locations
  );
  let industries = useSelector(
    (state: RootState) => state.searchFilter.categories
  );
  let employmentTypes = useSelector(
    (state: RootState) => state.searchFilter.employmentTypes
  );
  let distance = useSelector((state: RootState) => state.searchFilter.distance);
  let salaryMin = useSelector(
    (state: RootState) => state.searchFilter.salaryMin
  );
  let salaryMax = useSelector(
    (state: RootState) => state.searchFilter.salaryMax
  );

  useEffect(() => {
    let locations: string[] = clientAvailableLocations.map(
      (loc: Location) => `${loc.cityName}, ${loc.regionName}`
    );
    setAvailableSearchLocations(locations);
  }, [clientAvailableLocations]);

  useEffect(() => {
    store.dispatch(
      setKeyword(
        searchParams.has("keyword")
          ? (searchParams.get("keyword") as string)
          : ""
      )
    );
    store.dispatch(
      setLocations(
        searchParams.has("locations")
          ? (searchParams.getAll("locations") as string[])
          : []
      )
    );
    store.dispatch(
      setEmploymentType(
        searchParams.has("employmentTypes")
          ? (searchParams.getAll("employmentTypes") as string[])
          : []
      )
    );
    store.dispatch(
      setDistance(
        searchParams.has("distance")
          ? (searchParams.get("distance") as string)
          : ""
      )
    );
    store.dispatch(
      setCategories(
        searchParams.has("categories")
          ? (searchParams.getAll("categories") as string[])
          : []
      )
    );
    store.dispatch(
      setSalaryMin(
        searchParams.has("salaryMin")
          ? Number(searchParams.get("salaryMin"))
          : MIN_SALARY
      )
    );
    store.dispatch(
      setSalaryMax(
        searchParams.has("salaryMax")
          ? Number(searchParams.get("salaryMax"))
          : MAX_SALARY
      )
    );

    console.log(
      searchKeyword,
      locations,
      employmentTypes,
      distance,
      industries,
      salaryMin,
      salaryMax
    );
  }, [setSearchParams, searchParams]);

  async function searchJobs() {
    navigate(AppRoutes.JOB_SEARCH);
    let searchOptions: any = {
      locations: locations,
      employmentTypes: employmentTypes,
      categories: industries,
    };
    if (distance && distance.length) {
      searchOptions = {
        ...searchOptions,
        distance: distance,
      };
    }
    if (searchKeyword.length) {
      searchOptions = {
        ...searchOptions,
        keyword: searchKeyword,
      };
    }
    if (salaryMin !== MIN_SALARY) {
      searchOptions = {
        ...searchOptions,
        salaryMin: salaryMin,
      };
    }
    if (salaryMax !== MAX_SALARY) {
      searchOptions = {
        ...searchOptions,
        salaryMax: salaryMax,
      };
    }
    setSearchParams(searchOptions);
    setExpanded(false);
  }

  return (
    <Flex
      style={{ border: "2px solid black", borderRadius: 8 }}
      p={18}
      m={4}
      w={isDesktop ? "50%" : isTab ? "65%" : "80%"}
      direction={"column"}
    >
      <Flex wrap={isMobile ? "wrap" : "inherit"} w={"100%"}>
        <TextInput
          size={isDesktop ? "md" : isTab ? "sm" : "sm"}
          placeholder="Job title, skill or keyword"
          w={"100%"}
          value={searchKeyword}
          onChange={(event) => {
            store.dispatch(setKeyword(event.currentTarget.value));
          }}
        />
        <Flex w={"100%"} ml={isDesktop || isTab ? 8 : 0} mt={isMobile ? 8 : 0}>
          <CustomMultiSelect
            label={"Locations"}
            placeholder={"Select locations"}
            items={availableSearchLocations}
            defaultValues={locations}
            size={isDesktop ? "md" : isTab ? "sm" : "sm"}
            onValueChange={(values: string[]) => {
              store.dispatch(setLocations(values));
            }}
          />
        </Flex>

        <Flex w={"100%"} ml={isDesktop || isTab ? 8 : 0} mt={isMobile ? 8 : 0}>
          <Button
            c={"white"}
            bg={"black"}
            variant="light"
            w={"100%"}
            size={isDesktop ? "md" : isTab || isMobile ? "sm" : "xs"}
            onClick={searchJobs}
          >
            <Flex justify={"center"} align={"center"}>
              <IconSearch
                style={{ width: rem(20), height: rem(20) }}
                stroke={1.5}
              />
              <Text
                fw={500}
                size={isDesktop ? "md" : isTab ? "sm" : "xs"}
                ml={6}
              >
                Search
              </Text>
            </Flex>
          </Button>
          <ActionIcon
            variant="subtle"
            size={isDesktop ? "xl" : isTab || isMobile ? "lg" : "md"}
            w={"100%"}
            ml={6}
            onClick={() => setExpanded((prev) => !prev)}
          >
            <IconAdjustmentsAlt
              style={{ width: rem(32), height: rem(32) }}
              stroke={1.5}
            />
          </ActionIcon>
        </Flex>
      </Flex>

      {expanded && (isDesktop || isTab) && <Divider my={12} />}
      {expanded && (isDesktop || isTab) && (
        <Flex
          justify={"flex-end"}
          onClick={() => store.dispatch(resetSearchFilter())}
        >
          <Text
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Clear Applied Filters
          </Text>
        </Flex>
      )}

      {expanded && (isDesktop || isTab) && (
        <Flex mt={4} w={"100%"} align={"center"}>
          <CustomMultiSelect
            label="Job Types"
            placeholder="Select types"
            items={availableEmploymentTypes}
            defaultValues={employmentTypes}
            size={isDesktop ? "md" : isTab ? "sm" : "sm"}
            onValueChange={(values: string[]) => {
              store.dispatch(setEmploymentType(values));
            }}
          />

          <Space mr={6} />

          <CustomMultiSelect
            label="Categories"
            placeholder="Select categories"
            items={availableJobCategories}
            defaultValues={industries}
            size={isDesktop ? "md" : isTab ? "sm" : "sm"}
            onValueChange={(values: string[]) => {
              store.dispatch(setCategories(values));
            }}
          />
          <Space mr={6} />

          <Select
            placeholder="Distance"
            w={"100%"}
            data={[
              "10+ miles",
              "20+ miles",
              "30+ miles",
              "50+ miles",
              "70+ miles",
              "90+ miles",
              "150+ miles",
              "200+ miles",
            ]}
            size={isDesktop ? "md" : isTab ? "sm" : "sm"}
            defaultValue={distance}
            onChange={(value) => {
              store.dispatch(setDistance(value ? value : ""));
            }}
          />
          <Space mr={6} />

          <Flex direction={"column"} w={"100%"}>
            <Text fw={500} c={"#9f9f9f"}>
              Salary ($)
            </Text>
            <RangeSlider
              minRange={100000}
              min={MIN_SALARY}
              max={MAX_SALARY}
              size={isDesktop ? "md" : isTab ? "sm" : "sm"}
              step={100}
              defaultValue={[Number(salaryMin), Number(salaryMax)]}
              onChangeEnd={(value) => {
                store.dispatch(setSalaryMin(value[0]));
                store.dispatch(setSalaryMax(value[1]));
              }}
            />
          </Flex>
        </Flex>
      )}
      {expanded && isMobile && (
        <AdvanceMobileFilters
          expanded={expanded}
          setExpanded={setExpanded}
          availableEmploymentTypes={availableEmploymentTypes}
          availableJobCategories={availableJobCategories}
          searchJobs={searchJobs}
        />
      )}
    </Flex>
  );
}

export function AdvanceMobileFilters(props: any) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const expanded = props.expanded;
  const setExpanded = props.setExpanded;
  const availableEmploymentTypes = props.availableEmploymentTypes;
  const availableJobCategories = props.availableJobCategories;
  const searchJobs = props.searchJobs;

  const distance = useSelector(
    (state: RootState) => state.searchFilter.distance
  );
  const categories = useSelector(
    (state: RootState) => state.searchFilter.categories
  );
  const employmentTypes = useSelector(
    (state: RootState) => state.searchFilter.employmentTypes
  );
  const salaryMin = useSelector(
    (state: RootState) => state.searchFilter.salaryMin
  );
  const salaryMax = useSelector(
    (state: RootState) => state.searchFilter.salaryMax
  );

  const [expandJobType, setExpandJobType] = useState<boolean>(false);
  const [expandIndustry, setExpandIndustry] = useState<boolean>(false);
  const [expandDistance, setExpandDistance] = useState<boolean>(false);

  return (
    <Drawer
      opened={expanded}
      onClose={() => setExpanded(false)}
      position="bottom"
      styles={{
        content: {
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          height: "75vh",
        },
      }}
      withCloseButton={false}
    >
      <Flex
        bg={"#ffffff"}
        style={{ position: "sticky", top: 0, zIndex: 3 }}
        py={12}
        justify={"space-between"}
      >
        <Flex align={"center"}>
          <IconAdjustmentsAlt
            style={{
              width: rem(32),
              height: rem(32),
              marginRight: 6,
            }}
            stroke={1.5}
          />
          <Flex direction={"column"}>
            <Text size="lg" fw={500}>
              Filters
            </Text>
            <Text size="sm" fw={500} c={"#afafaf"}>
              Advance your job search
            </Text>
          </Flex>
        </Flex>

        <Button
          variant="subtle"
          fw={500}
          onClick={() => {
            store.dispatch(resetSearchFilter());
          }}
        >
          Clear All
        </Button>
      </Flex>

      <Space h={12} />

      <Flex
        style={{ cursor: "pointer", borderRadius: 6 }}
        onClick={() => setExpandJobType((prev) => !prev)}
        bg={expandJobType ? "#efefef" : "#ffffff"}
        p={6}
      >
        <Button fw={500} variant="transparent" p={2}>
          {!expandJobType ? (
            <IconChevronRight
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          ) : (
            <IconChevronDown
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          )}
          Job Type
        </Button>
      </Flex>
      <Collapse in={expandJobType} p={6}>
        {availableEmploymentTypes.map((employmentType: string) => (
          <Checkbox
            label={employmentType}
            m={8}
            checked={employmentTypes.includes(employmentType)}
            onChange={(event) => {
              console.log("Event");
              store.dispatch(
                setEmploymentType(
                  event.currentTarget.checked
                    ? [...employmentTypes, employmentType]
                    : employmentTypes.filter(
                        (type: string) => type !== employmentType
                      )
                )
              );
            }}
          />
        ))}
        {availableEmploymentTypes.length === 0 ? (
          <Flex justify={"center"} align={"center"} my={12}>
            <Text size="sm" fw={500} c={"#9f9f9f"}>
              No Employment types available
            </Text>
          </Flex>
        ) : (
          <></>
        )}
      </Collapse>

      <Flex
        style={{ cursor: "pointer", borderRadius: 6 }}
        onClick={() => setExpandIndustry((prev) => !prev)}
        bg={expandIndustry ? "#efefef" : "#ffffff"}
        p={6}
      >
        <Button fw={500} variant="transparent" p={2}>
          {!expandIndustry ? (
            <IconChevronRight
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          ) : (
            <IconChevronDown
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          )}
          Industry
        </Button>
      </Flex>
      <Collapse in={expandIndustry} p={6}>
        {availableJobCategories.map((category: string) => (
          <Checkbox
            label={category}
            m={8}
            checked={categories.includes(category)}
            onChange={(event) => {
              store.dispatch(
                setCategories(
                  event.currentTarget.checked
                    ? [...categories, category]
                    : categories.filter((type: string) => type !== category)
                )
              );
            }}
          />
        ))}
        {availableJobCategories.length === 0 ? (
          <Flex justify={"center"} align={"center"} my={12}>
            <Text size="sm" fw={500} c={"#9f9f9f"}>
              No industries available
            </Text>
          </Flex>
        ) : (
          <></>
        )}
      </Collapse>

      <Flex
        style={{ cursor: "pointer", borderRadius: 6 }}
        onClick={() => setExpandDistance((prev) => !prev)}
        bg={expandDistance ? "#efefef" : "#ffffff"}
        p={6}
      >
        <Button fw={500} variant="transparent" p={2}>
          {!expandDistance ? (
            <IconChevronRight
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          ) : (
            <IconChevronDown
              style={{
                width: rem(24),
                height: rem(24),
                marginRight: 6,
              }}
              stroke={1.5}
            />
          )}
          Distance
        </Button>
      </Flex>

      <Collapse in={expandDistance} p={6}>
        {[
          "10+ miles",
          "20+ miles",
          "30+ miles",
          "50+ miles",
          "70+ miles",
          "90+ miles",
          "150+ miles",
          "200+ miles",
        ].map((d: string, index: number) => (
          <Checkbox
            key={index}
            label={d}
            checked={distance === d}
            m={8}
            onChange={(event) => {
              store.dispatch(setDistance(event.currentTarget.checked ? d : ""));
            }}
          />
        ))}
      </Collapse>
      <Flex direction={"column"} w={"100%"} px={16} pb={12}>
        <Text fw={500} c={"#9f9f9f"}>
          Salary ($)
        </Text>
        <RangeSlider
          minRange={100000}
          min={MIN_SALARY}
          max={MAX_SALARY}
          size={isDesktop ? "md" : isTab ? "sm" : "sm"}
          step={10000}
          value={[salaryMin, salaryMax]}
          onChangeEnd={(value) => {
            store.dispatch(setSalaryMin(value[0]));
            store.dispatch(setSalaryMax(value[1]));
          }}
        />
      </Flex>
      <Flex
        style={{
          position: "sticky",
          bottom: 0,
        }}
        w={"100%"}
        bg={"#ffffff"}
      >
        <Button
          variant="subtle"
          w={"100%"}
          size="md"
          onClick={() => setExpanded(false)}
        >
          Cancle
        </Button>
        <Button w={"100%"} size="md" onClick={searchJobs}>
          Apply
        </Button>
      </Flex>
    </Drawer>
  );
}
