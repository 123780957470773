import { HttpStatusCode } from "axios";
import { SearchApiConnector } from "../serverConnectors/searchApiConnector";
import { EmployerCountByCategory, Job, JobCountByCategory, SearchFilterQuery } from "../types";

export class JobService extends SearchApiConnector {

	async findJobs(searchFitler: SearchFilterQuery): Promise<{ jobs: Job[], count: number }> {
		let jobs: Job[] = [];
		let count: number = 0;
		try {
			const response = await this.axios.post("/job/search", searchFitler);
			if (response.status === HttpStatusCode.Ok) {
				console.log(response.data)
				jobs = response.data.data;
				count = response.data.count;
			}
		}
		catch (error: any) {
			console.log("Error: ", error.message);
		}
		return { jobs: jobs, count: count };
	}

	async getJobCountByCategory(countryName: string): Promise<JobCountByCategory[]> {
		let jobCountByCategories: JobCountByCategory[] = [];
		try {
			const response = await this.axios.get(`/job/find/count/category/${countryName}`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("getMostDemandingCategories: ", response.data);
				jobCountByCategories = response.data.data;
			}
		}
		catch (error: any) {
			console.log("Error: ", error.message);
		}
		return jobCountByCategories;
	}

	async getEmployerCountByCategory(countryName: string): Promise<EmployerCountByCategory[]> {
		let employerCountByCategories: EmployerCountByCategory[] = [];
		try {
			const response = await this.axios.get(`/job/find/count/employer/category/${countryName}`);
			if (response.status === HttpStatusCode.Ok) {
				employerCountByCategories = response.data.data;
			}
		}
		catch (error: any) {
			console.log("Error: ", error.message);
		}
		return employerCountByCategories;
	}

	async findJobCategoryByCountry(countryName: string): Promise<string[]> {
		let categories: string[] = [];
		try {
			const response = await this.axios.get(`/job/category/${countryName}`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("response.data.data", response.data.data);
				categories = response.data.data.map((item: { category: string }) => item.category);
			}
		}
		catch (error: any) {
			console.log("Error: ", error);
		}
		return categories;
	}

	async findEmployersByCountry(countryName: string): Promise<string[]> {
		let employers: string[] = [];
		try {
			const response = await this.axios.get(`/job/employers/${countryName}`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("findEmployersByCountry: ", response.data.data);
				employers = response.data.data.map((item: { employer: string }) => item.employer);
			}
		}
		catch (error: any) {
			console.log("Error: ", error);
		}
		return employers;
	}

	async findJobEmploymentTypesByCountry(countryName: string): Promise<string[]> {
		let employmentTypes: string[] = [];
		try {
			const response = await this.axios.get(`/job/employment-type/${countryName}`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("response.data.data", response.data.data);
				employmentTypes = response.data.data.map((item: { employment_type: string }) => item.employment_type);
			}
		}
		catch (error: any) {
			console.log("Error: ", error);
		}
		return employmentTypes;
	}

	async findAllJobsCount(): Promise<number> {
		let jobCount: number = 0;
		try {
			const response = await this.axios.get(`job/find/count/all`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("response.data.data", response.data.data);
				jobCount = response.data.data[0].jobCount;
			}
		}
		catch(error: any) {
			console.log("Error: ", error);
		}
		return jobCount;
	}

	async findAllEmployersCount(): Promise<number> {
		let employersCount: number = 0;
		try {
			const response = await this.axios.get(`job/find/count/all-employer`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("response.data.data", response.data.data);
				employersCount = response.data.data[0].employerCount;
			}
		}
		catch(error: any) {
			console.log("Error: ", error);
		}
		return employersCount;
	}

	async findAllIndustryCount(): Promise<number> {
		let industryCount: number = 0;
		try {
			const response = await this.axios.get(`job/find/count/all-industry`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("response.data.data", response.data.data);
				industryCount = response.data.data[0].industryCount;
			}
		}
		catch(error: any) {
			console.log("Error: ", error);
		}
		return industryCount;
	}

	async findJobBySenderReference(senderReference: string): Promise<Job[]> {
		let jobs: Job[] = []
		try {
			const response = await this.axios.get(`job/find/sr/${senderReference}`);
			if (response.status === HttpStatusCode.Ok) {
				console.log("findJobBySenderReference: ", response.data.data);
				jobs = response.data.data;
			}
		}
		catch (error: any) {
			console.log("Error: ", error);
		}
		return jobs;
	}
}
