import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';


dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export class DateUtils {

    static getTimeFromNow(timestampString: string): string {
        const timestamp = parseInt(timestampString);
        const now = dayjs();
        const past = dayjs(timestamp * 1000);

        return now.to(past);
    }

    static getCurrentYear(): number {
        return dayjs().year();
    }

    static getCurrentMonthName(): string {
        return dayjs().format('MMMM');
    }
}