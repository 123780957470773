import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  PasswordInput,
  Radio,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import { ReactComponent as IconLoginPage } from "../../assets/iconSignUpPage.svg";
import { ReactComponent as IconGoogle } from "../../assets/iconGoogle.svg";
import { AppRoutes, Fonts } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";
import { useNavigate } from "react-router-dom";

export function LoginPage(props: {}) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);
  const navigate = useNavigate();

  return (
    <Flex w={"100%"} p={32}>
      <Flex w={"100%"} align={"center"} p={16} direction={"column"}>
        <Flex direction={"column"} w={"100%"} align={"center"}>
          <Text
            ff={Fonts.NEWSREADER}
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(40) : rem(32),
            }}
            fw={500}
          >
            Welcome Back!
          </Text>
          <Text size="sm" fw={500} c={"#4d4d4d"}>
            We have missed you a lot, we hope you too!
          </Text>
        </Flex>
        <Flex
          bg={"#efefef"}
          p={32}
          my={16}
          style={{ borderRadius: 8 }}
          direction={"column"}
          w={isDesktop ? "55%" : isTab ? "50%" : "100%"}
        >
          <TextInput
            label="Email"
            placeholder="Enter your email"
            my={8}
            w={"100%"}
          />
          <PasswordInput
            label="Password"
            type="password"
            placeholder="Enter your password"
            my={8}
            w={"100%"}
          />

          <Flex justify={"flex-end"} my={8} style={{ cursor: "pointer" }}>
            <Text td={"underline"} c={"#4d4d4d"} size={isMobile ? "sm" : "md"}>
              Forgot Password?
            </Text>
          </Flex>

          <Button color="black" my={8}>
            <Text size="sm">LOG IN</Text>
          </Button>
          <Flex justify={"center"} my={4}>
            <Text size="sm" mr={4}>
              Do not have an account?
            </Text>
            <Text
              size="sm"
              fw={500}
              td={"underline"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${AppRoutes.SIGNUP}`)}
            >
              Sign Up
            </Text>
          </Flex>

          <Divider my={32} />

          <Button bg={"#ffffff"} c={"#000000"} my={8}>
            <Flex align={"center"}>
              <IconGoogle />
              <Text size="sm" ml={4}>
                Continue with Google
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex w={"100%"}>
          <IconLoginPage />
        </Flex>
      )}
    </Flex>
  );
}
