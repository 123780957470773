import axios, { AxiosInstance } from "axios";
import { environment } from "../environment";

export class LocationApiConnector {
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: environment.locationApiUrl
        })
    }
};