import {
  Anchor,
  Button,
  Checkbox,
  Divider,
  Flex,
  Group,
  PasswordInput,
  Radio,
  rem,
  Text,
  TextInput,
} from "@mantine/core";
import { ReactComponent as IconSignUpPage } from "../../assets/iconSignUpPage.svg";
import { ReactComponent as IconGoogle } from "../../assets/iconGoogle.svg";
import { AppRoutes, Fonts } from "../../models";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";
import { useNavigate } from "react-router-dom";

export function SignUpPage(props: {}) {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const navigate = useNavigate();

  return (
    <Flex w={"100%"} p={32}>
      <Flex w={"100%"} align={"center"} p={16} direction={"column"}>
        <Flex direction={"column"} w={"100%"} align={"center"}>
          <Text
            ff={Fonts.NEWSREADER}
            style={{
              fontSize: isDesktop ? rem(48) : isTab ? rem(40) : rem(32),
            }}
            fw={500}
          >
            Start your journey now!
          </Text>
          <Text size="sm" fw={500} c={"#4d4d4d"}>
            At jobsrmine, we provide job seekers and employers with a fast and
            efficient
            <br />
            approach for job hunting and job posting. 
          </Text>
        </Flex>
        <Flex
          bg={"#efefef"}
          p={32}
          my={16}
          style={{ borderRadius: 8 }}
          direction={"column"}
          w={isDesktop ? "55%" : isTab ? "50%" : "100%"}
        >
          <TextInput
            label="Email"
            placeholder="Enter your email"
            my={8}
            w={"100%"}
          />
          <PasswordInput
            label="Password"
            type="password"
            placeholder="Enter your password"
            my={8}
            w={"100%"}
          />

          <Radio.Group my={8}>
            <Flex>
              <Radio label="Job seeker" mr={16} />
              <Radio label="Advertiser" />
            </Flex>
          </Radio.Group>

          <Checkbox my={8} label="I agree to the terms and conditions" />

          <Button color="black" my={8}>
            <Text size="sm">SIGN UP</Text>
          </Button>
          <Flex justify={"center"} my={4}>
            <Text size="sm" mr={4}>
              Have an account?
            </Text>
            <Text
              size="sm"
              fw={500}
              td={"underline"}
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`${AppRoutes.LOGIN}`)}
            >
              Log in
            </Text>
          </Flex>

          <Divider my={32} />

          <Button bg={"#ffffff"} c={"#000000"} my={8}>
            <Flex align={"center"}>
              <IconGoogle />
              <Text size="sm" ml={4}>
                Sign up with Google
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {isDesktop && (
        <Flex w={"100%"}>
          <IconSignUpPage />
        </Flex>
      )}
    </Flex>
  );
}
